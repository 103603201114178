import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import InstarVisionMenuCards01 from 'components/Software/Windows/InstarVision/Camera_List/instarVisionMenuCards01';
import InstarVisionMenuCards02 from 'components/Software/Windows/InstarVision/Camera_List/instarVisionMenuCards02';
import InstarVisionCard01 from 'components/Software/Windows/InstarVision/Camera_List/instarVisionCard01';
import InstarVisionCards01 from 'components/Software/Windows/InstarVision/Camera_List/instarVisionCards01';
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for Windows",
  "path": "/Software/Windows/InstarVision/Camera_List/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "The camera list displays all added cameras for an easy configuration. Besides network IP camera, you can also add USB webcams and remote instances of the InstarVision Surveillance System. Just click on the + button at the bottom or right-click on Camera to open the Add Camera dialogue.",
  "image": "../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='InstarVision v3 for Windows' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/InstarVision/Camera_List/' locationFR='/fr/Software/Windows/InstarVision/Camera_List/' crumbLabel="Camera List" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b04bc1fdb5052ddf42866c2fcd453375/29114/Banner_en-InstarVision2-0_Camera-List.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgElEQVQI142HsQrCMBQA36/o9zm4KXZ3aOIUcVEX9VdcXCuk4gcUN/GF1jSUE+0uPTiOE7oIjcI7QFtD/eqfxFCcc8wXGaQGMdeALRT7rY/YW8KWCVO2GB//mvvI6t6RLS2z6YS8UET2D2RXIdsKOTyRkyJH7TvIwHh9ZrS5/P4D1XnA0mI/2psAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b04bc1fdb5052ddf42866c2fcd453375/e4706/Banner_en-InstarVision2-0_Camera-List.avif 230w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/d1af7/Banner_en-InstarVision2-0_Camera-List.avif 460w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/7f308/Banner_en-InstarVision2-0_Camera-List.avif 920w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/e1c99/Banner_en-InstarVision2-0_Camera-List.avif 1380w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/76ea5/Banner_en-InstarVision2-0_Camera-List.avif 1840w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/f696c/Banner_en-InstarVision2-0_Camera-List.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b04bc1fdb5052ddf42866c2fcd453375/a0b58/Banner_en-InstarVision2-0_Camera-List.webp 230w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/bc10c/Banner_en-InstarVision2-0_Camera-List.webp 460w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/966d8/Banner_en-InstarVision2-0_Camera-List.webp 920w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/445df/Banner_en-InstarVision2-0_Camera-List.webp 1380w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/78de1/Banner_en-InstarVision2-0_Camera-List.webp 1840w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/882b9/Banner_en-InstarVision2-0_Camera-List.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b04bc1fdb5052ddf42866c2fcd453375/81c8e/Banner_en-InstarVision2-0_Camera-List.png 230w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/08a84/Banner_en-InstarVision2-0_Camera-List.png 460w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/c0255/Banner_en-InstarVision2-0_Camera-List.png 920w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/b1001/Banner_en-InstarVision2-0_Camera-List.png 1380w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/161ec/Banner_en-InstarVision2-0_Camera-List.png 1840w", "/en/static/b04bc1fdb5052ddf42866c2fcd453375/29114/Banner_en-InstarVision2-0_Camera-List.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b04bc1fdb5052ddf42866c2fcd453375/c0255/Banner_en-InstarVision2-0_Camera-List.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---camera-list",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---camera-list",
        "aria-label": "side navigation   camera list permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - Camera List`}</h2>
    <p>{`The camera list displays all added cameras for an easy configuration. Besides network IP camera, you can also add USB webcams and remote instances of the InstarVision Surveillance System. Just click on the + button at the bottom or right-click on Camera to open the Add Camera dialogue:`}</p>
    <h3 {...{
      "id": "add-instar-ip-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-instar-ip-cameras",
        "aria-label": "add instar ip cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add INSTAR IP Cameras`}</h3>
    <p>{`The Add Camera menu allows you to add three different types of cameras. A USB camera can be the camera on the laptop the Surveillance Center is installed on, or Webcams connected to your servers USB interface. Network Cameras are IP cameras on your local network (e.g. local IP´s like 192.168.1.101) as well as cameras you want to connect to over the internet (e.g. DDNS addresses like abcd12.ddns-instar.de). The InstarVision Remote Access refers to a function of the Surveillance Center that enables you to connect to a second remote InstarVision installation. The list order defines the camera viewport arrangement on the video canvas.`}</p>
    <p><img parentName="p" {...{
        "src": "/en/3a5600f58dd1c43d756fc8e180335b1b/InstarVision_Camera_List_add_IP_Camera.gif",
        "alt": "InstarVision v3 for Windows: Add a Camera"
      }}></img></p>
    <p>{`Just click on the + button or right-click Camera in the Camera List.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <InstarVisionMenuCards01 mdxType="InstarVisionMenuCards01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Right-Click an added camera in the list to deactivate, delete or duplicate it. The properties option allows you to adjust its network and video parameters, which were set when you added the camera. Under Bitrate you can adjust the quality of the video stream that is retrieved from the camera. Please be aware that high quality settings result in high bandwidth requirements - as well as high demands on your computers CPU.`}</p>
    <h3 {...{
      "id": "add-instar-p2p-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-instar-p2p-cameras",
        "aria-label": "add instar p2p cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add INSTAR P2P Cameras`}</h3>
    <p>{`To add a camera via it's P2P UID, you can follow the steps above, just switch the search mode to P2P, select your camera from the result list, type in your login and confirm.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "591px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9441d3040a73d9bdad636e18021615b5/e4c9a/InstarVision_Add_P2P_Camera_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADH0lEQVQ4yz2SS2skVRTH6wMo6FpGNMFNi7pz69qv4GaWutFRQRxBEJe+UNyGiePMmERwLQgDojPGJJ2k05NXP9JdXUl3PW7d9/tWVd+S6p7447843Nf5n3NPgDGOZ3ESx0JKrfV0QRzHcZKkSTKZTBjjWmsppbqGUgpyAAAIOOeEEEqpNg0AgDzPIYQQIQghIVgIIaU0RmutlVKCcyGE0QZjHDDGkjjOc+S911pTSqWUzQXR4KxFCCZJCiHiXDDGCMYIIcaYUiqglEynM8FFVVXGGCmltUYpJZWSUmpjlq9zxpeetdZ24dFa29gGOVBKee+NacxwzpMkCcPw8uoqDMPRaBSG4SQMoyiahJPxOMQYM8aklI1tADKEUQ5yuWBZm1nStOppwuW6XsRPM2NC4jRLAWBCautADpaH0jRFTcNIWZaMc6kNREgohQlVxkCEESGBYUTBTMOsrtx8XhCE/u+HlJIQAhGSOK8LWztdV64uTD13daHnzgTHufwjog8v6aPc7UBLheSUaq2993PvrXWC0f2M/wXsNip3cbGDim3o/oZFn5fBvRH7aB9+3iWfHsIvuyjjymklhODNfzb1emt+7NGb/2Tv/Zve2sveb8Pbh+jd3XxtQIM0Cq/Oj/NonAx7V/2z87OGbrd7etoEvV5vMBjE42F6cQ7CC4OAr2y9kC9t0Ov3DzqdoydPDjpH+weH++323t5eu93udDqdo87J6enJyfFydzgaU8aNK1xZNSrKYDHFyXQ6TdMkS7M0TePFrEdRlCzIQJbnOUJoNmtmnjFWVVVZlkVRBELIuq4r76u5d0Vpi8KVpSvLsppXzlXOWdtMlHOuLKuiKJxzyhihjdYmSONp/+wkvBiMhz1BsRZMC6Y4hVmSMDaj1BpTey84r6/5/rL+bFSvpz74/c/H293+7vHwaJRMiLukRUTcTPh2P9pZW9u+s76+sXV3c+vOg19+2tha6rufN7++u/nDvc3gzXduv/3Fb299cv/1Dzdeu/Wg0Qf33/j411dufvXtM8998+zzL6y+emOl9eJK68a1Xl5tray2Xlpp/Qem0XhErRjJAwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9441d3040a73d9bdad636e18021615b5/e4706/InstarVision_Add_P2P_Camera_02.avif 230w", "/en/static/9441d3040a73d9bdad636e18021615b5/d1af7/InstarVision_Add_P2P_Camera_02.avif 460w", "/en/static/9441d3040a73d9bdad636e18021615b5/6c4a9/InstarVision_Add_P2P_Camera_02.avif 591w"],
              "sizes": "(max-width: 591px) 100vw, 591px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9441d3040a73d9bdad636e18021615b5/a0b58/InstarVision_Add_P2P_Camera_02.webp 230w", "/en/static/9441d3040a73d9bdad636e18021615b5/bc10c/InstarVision_Add_P2P_Camera_02.webp 460w", "/en/static/9441d3040a73d9bdad636e18021615b5/48c21/InstarVision_Add_P2P_Camera_02.webp 591w"],
              "sizes": "(max-width: 591px) 100vw, 591px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9441d3040a73d9bdad636e18021615b5/81c8e/InstarVision_Add_P2P_Camera_02.png 230w", "/en/static/9441d3040a73d9bdad636e18021615b5/08a84/InstarVision_Add_P2P_Camera_02.png 460w", "/en/static/9441d3040a73d9bdad636e18021615b5/e4c9a/InstarVision_Add_P2P_Camera_02.png 591w"],
              "sizes": "(max-width: 591px) 100vw, 591px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9441d3040a73d9bdad636e18021615b5/e4c9a/InstarVision_Add_P2P_Camera_02.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If your camera is not inside the same network, you can manually switch the Camera Model to the `}<strong parentName="p">{`P2P Mode`}</strong>{`, type in your P2P UID (`}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/Remote_Access/"
      }}>{`1080p Models`}</a>{` / `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Network/P2P/"
      }}>{`720p Models`}</a>{`) and login and confirm to add your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "589px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e29f819d597ba21092754ceefd9e751/b1665/InstarVision_Add_P2P_Camera_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB30lEQVQoz1WRu2tUURDG7z9uIYgipLASLCQqGIJtGq2SKn2IblYD7n3vnuec15zZc+5L7koEf9UUM/N9800BYNiBaa3neUZErbWzFhDNMHR997h92G42fd9ba80TUsrD4YAYC6X1/oRzjojapqk5Dzc3+fw8huDG0aWEiEQUnwghGGMYY4VzTgNYa3PORNTU1a5uuru7/va2ZSxfXR2vrw2idx4Rvfd/LSCiMaaw1nR9r7SepinGKKUQQlRNy8FIgHx/Hzc/9owDwDocgtbAuXDOIWIBAF3XIeI0z0SEiMd07LsOAFadlIW1XdsqpY0xzjklJQBEWlltSykRcRiGGOMamHNc8KqqmqYtq2q325VlWZVVXdVlWe7KEk5neu9XZcaYlJJzEQKGEGJc9x7/J6X0rz6prg2ntBlnQoRIdEyMMaIYMPT7vRBCKZVzBmNCJCGlC0FpHSJJpYRSRbJwlIejZMuUpzGDUpxzY0xKiYisXV8StVimtEx5mYdlzssyLHNaxlR8a/3bLXx6NB/L+KWOJmDwnojGE6vL4L+2/v1v/FzTZR0va7qo44cyXjMqLur4fBPe/cazrT/7GTCPyzQN4zicyMOwLNNlHZ999y8f/Outf7X1b36FFxt3UeEfTu5gQ2aGCywAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e29f819d597ba21092754ceefd9e751/e4706/InstarVision_Add_P2P_Camera_01.avif 230w", "/en/static/0e29f819d597ba21092754ceefd9e751/d1af7/InstarVision_Add_P2P_Camera_01.avif 460w", "/en/static/0e29f819d597ba21092754ceefd9e751/0a6c2/InstarVision_Add_P2P_Camera_01.avif 589w"],
              "sizes": "(max-width: 589px) 100vw, 589px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e29f819d597ba21092754ceefd9e751/a0b58/InstarVision_Add_P2P_Camera_01.webp 230w", "/en/static/0e29f819d597ba21092754ceefd9e751/bc10c/InstarVision_Add_P2P_Camera_01.webp 460w", "/en/static/0e29f819d597ba21092754ceefd9e751/339cb/InstarVision_Add_P2P_Camera_01.webp 589w"],
              "sizes": "(max-width: 589px) 100vw, 589px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e29f819d597ba21092754ceefd9e751/81c8e/InstarVision_Add_P2P_Camera_01.png 230w", "/en/static/0e29f819d597ba21092754ceefd9e751/08a84/InstarVision_Add_P2P_Camera_01.png 460w", "/en/static/0e29f819d597ba21092754ceefd9e751/b1665/InstarVision_Add_P2P_Camera_01.png 589w"],
              "sizes": "(max-width: 589px) 100vw, 589px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e29f819d597ba21092754ceefd9e751/b1665/InstarVision_Add_P2P_Camera_01.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Also check out our free mobile app InstarVision - here you can use the network scanner to find cameras on your local network. Or open the P2P menu (`}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/Remote_Access/"
      }}>{`1080p Series`}</a>{` / `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Network/P2P/"
      }}>{`720p Series`}</a>{`) to scan your camera´s QRcode for the easiest way to add your camera via it's P2P UID:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Windows_Phone/P2P/"
        }}>{`Windows Phone`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Metro/P2P/"
        }}>{`Windows 8/10 (Metro)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/P2P/"
        }}>{`iPhone`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/P2P/"
        }}>{`iPad`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Android/InstarVision/P2P/"
        }}>{`Android`}</a></li>
    </ul>
    <h3 {...{
      "id": "add-usb-webcams",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-usb-webcams",
        "aria-label": "add usb webcams permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add USB Webcams`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "439px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d893264fd69dd469bfe7fa86e45ec869/e3b18/Image015.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACNUlEQVQoz03R22rbMBgHcD/sbnboTQuDscFu0gY6xhpDX2PsFca2QLsDa2LH8TmOUtuKrJPlyAe19rC9QH8gJIT+fNInTdf1q6PZQNf16XR6fnE+mUym04vJZHJ5eanr+mw2G4991GefX774cnKizefzzWbjuq7neZZlLZdLwzANw1g+YRiGObAsyxtEUfT92zfNdR1GGcYYpgjjbLfbQYja3mM/jh4GjVKUEkKIUsp1Xc1xHKWUlIeyLIUQuRCc835xVDwx7lNKq7KyVittbVkZymAKYbqHECZpGgZ+EPhhGCRJEvhBGEZ5LqQ8jPnD4ZDneRzHhmFonueVssQYCyEoo3F8DyGklDLG9hAqpXLOy7Kiw3X5gBBCKV2tVn1YSrnfI84ZpRTuIWNMqYeqqrbbbV3XBGec5whlVVU1TVPXtZQSIdRX9n1f5AJEW845QigKN67jRptofDBjbJg5YwwAoBrVt61puq5br9d9WClFMiylLIqCUYozzDlXSjVP1HWNEKoHUsr/Ydu2OWMYE4zJ0LAEABCFURBuXD/0/NB2HdsLPMcDu3vXD5N0nxEK4sQwTc1x7EIISigldPwYSvriDwXvCtYW7DHP2oJTjNUhbwVt67Jtm66R1srUgiDouq5t227UduPqk18/u5Pv19Urozozy1y112H9fFFO7PJ0Ubx22lvT1haLu7FV2RHKMoazq7/xyQ9w/nN3NgdvbkCUouu7+HQOPvy5f3sD3v2CX29//wO/QqzA7W4rSwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d893264fd69dd469bfe7fa86e45ec869/e4706/Image015.avif 230w", "/en/static/d893264fd69dd469bfe7fa86e45ec869/4388e/Image015.avif 439w"],
              "sizes": "(max-width: 439px) 100vw, 439px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d893264fd69dd469bfe7fa86e45ec869/a0b58/Image015.webp 230w", "/en/static/d893264fd69dd469bfe7fa86e45ec869/023f2/Image015.webp 439w"],
              "sizes": "(max-width: 439px) 100vw, 439px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d893264fd69dd469bfe7fa86e45ec869/81c8e/Image015.png 230w", "/en/static/d893264fd69dd469bfe7fa86e45ec869/e3b18/Image015.png 439w"],
              "sizes": "(max-width: 439px) 100vw, 439px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d893264fd69dd469bfe7fa86e45ec869/e3b18/Image015.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The InstarVision Surveillance Center automatically detects cameras attached to the PC or laptop. Just select the video and audio source from the list and click OK, to add it to your Camera List. If you are using video capture cards, please install a WDM driver for it to live stream the video. In case of camcorders or external USB Webcams, please attach them to your PC´s USB interface and restart your PC to make them available as a video source.`}</p>
    <p>{`The video source you add here, will be available for all advanced features of the Surveillance Center - turning your laptop camera into a full-fledged Surveillance System. You want to know if someone uses your Laptop or enters your office without permission? The InstarVision Surveillance Center is the solution for your peace of mind.`}</p>
    <h3 {...{
      "id": "add-network-ip-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-network-ip-cameras",
        "aria-label": "add network ip cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add Network IP Cameras`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "594px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/216423d5951641c81b9a1e8c16eddb0b/5fd3e/Image017.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC0klEQVQ4y12Rz28dNRDH9z+FC9wqIdEDJ4S4UlAVUThxQOLCX1BCo6ekSUq5NG0KSR55L7zsb3vttcf22N5d7xrtpoSKrz4az9jj8Wic7CpW1g1hwvbBdgF9f49xvUKv0BvXv7/v+uCHSSibaNtJwSmlLefWWimEVkop0EoJIbI0vd3thGgB5D2csbqunLUJoKekruuqrmsAyPMsy7KiKIo8v03TPM/TNKWUeu/tv0JEpVRd14l2vdG6YQwAnHNSSkppmqWEEABARLZISqmUVkqLRVrP/SXGD967siw55533AKCUyrJMSomIfd8bY+4LIaIQgjGmtXbOJVI7ANk0DeMcEY0xztqyLAkhfFFVVWma1jVhTcM5J4RIKa218+UWkHMuQXLOAUBKCaAYY0VRlGVVFEWaZnmeF4uyfB7HkjM3knBp6qpqKK0JmTtWChHnqSy1nXO+87P17+y8LjObXwb0FW1KyigXgLYidD4ypizLZpHznrRCaiwpU9o0i1/RphFy/ueghGXEtixOgwNR1nNyP4xdP4DSjJBB8il0MY5hGKYpxBhiHGMMCTPdk636ei33ruGbDe5XGCc/jl2MwzjnDRtlv7iAva358srssPu5sI82Zu8Gv78xCQH32fP24ZH49FB8cigevVSrK/zlwuxf6Kd/qtXa/Phaf3TQPli1H/zKfzqTn5/Ijw/Eh0/lV69UwgHfrKuzNTnftufX5NWanFzSF2v2Ys2OL5uTS/L7Ffljw8437O2W/3ZFXq/JuvCX1dhCSISyQ4cxDnHq3xH7Obxj2ZnGPo5dnLo5vHNiF0KftMo678M49UP4HyGEOA5xHIYQ7kq+T9cPCWgrW65AGq0smv/QqgZ92vgj2pVCus2x2Rzj30cz18/Nzcuh80nKzNU2ffvXblexRjkicQYsacRqSx++0Q/OcH9T0tWT7OC77PRxdvo4f/bt7eEPDeA/ylSoGTfO+qUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/216423d5951641c81b9a1e8c16eddb0b/e4706/Image017.avif 230w", "/en/static/216423d5951641c81b9a1e8c16eddb0b/d1af7/Image017.avif 460w", "/en/static/216423d5951641c81b9a1e8c16eddb0b/2ce6e/Image017.avif 594w"],
              "sizes": "(max-width: 594px) 100vw, 594px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/216423d5951641c81b9a1e8c16eddb0b/a0b58/Image017.webp 230w", "/en/static/216423d5951641c81b9a1e8c16eddb0b/bc10c/Image017.webp 460w", "/en/static/216423d5951641c81b9a1e8c16eddb0b/bc223/Image017.webp 594w"],
              "sizes": "(max-width: 594px) 100vw, 594px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/216423d5951641c81b9a1e8c16eddb0b/81c8e/Image017.png 230w", "/en/static/216423d5951641c81b9a1e8c16eddb0b/08a84/Image017.png 460w", "/en/static/216423d5951641c81b9a1e8c16eddb0b/5fd3e/Image017.png 594w"],
              "sizes": "(max-width: 594px) 100vw, 594px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/216423d5951641c81b9a1e8c16eddb0b/5fd3e/Image017.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The InstarVision Surveillance Center automatically detects INSTAR Full HD, HD and VGA cameras on your local network. Open the Search window, select your camera type (FullHD, HD or VGA) and click on search. Selecting a camera from the list automatically fills out the needed parameters above. Please adjust your private camera login - you don´t have to change the Video/Audio Access or Ports anymore. Then click OK to add the camera to your Camera List.`}</p>
    <p>{`If you want to add a camera that is not on your local network, it is best practice to simply add a local camera first, duplicate it in the camera list and then change the IP address to the Internet Address (DDNS) of your remote camera and adjust the Port and Login.`}</p>
    <p><strong parentName="p">{`The default Video Path`}</strong>{` for INSTAR IP cameras is `}<code>{`livestream/11?action=play&media=video_audio_data`}</code>{` for FullHD and HD and `}<code>{`videostream.cgi`}</code>{` for VGA cameras.`}</p>
    <p>{`If you want to add a third-party camera, please adjust the video and audio path according to your manufactures documentation. The Search function might work - if your third-party camera uses the same chipset as an INSTAR camera. But it is more likely that you have to type in the parameters manually. You can also check the `}<a href="http://www.ispyconnect.com/sources.aspx" target="_blank" rel="noopener noreferrer">{`iSpy Database`}</a>{` for the most common third-party camera models.`}</p>
    <h3 {...{
      "id": "add-a-remote-instance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-remote-instance",
        "aria-label": "add a remote instance permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Remote Instance`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "412px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d38d0cc5e305767c8bae4b3f64130be8/9e32a/Image018.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAACO0lEQVQoz11SW47TQBD0tRA/ewJOgPaLIyCE9pfflfjgBFyAOywIIbFCZB9ZspHIwx47iR17PDM90z2vGI2ziIhSqT9aapWqq7J80/xeFcuiWhbVqijzareutq0ACgfjgnEBfTzlcQnk2LbOhAJLaIksERoNAEpKRPTeh+CHYYgx+hPEGIfDYRiGEHwGhlhRFAUr8pzzvu978YS+Ktnd/XQymWw2GyFE13VCiLppqpItVksuRKbJag0KNCISkTHGjvDeW2ullADgRsEQQowREaUUbdcZYzIwuNttS8YWi1XbdX3Pu66TUhZ58TCd3t7eLRaL2ePjw/T+YTqdzeYlKwFASklEmUbrrCVErTXhk7JzDgC0BoNI1lICAoAQUmvt/iJD55u6Zix51loLIfI8n8/nSoHWOl+vqqpijC2XKwDgXbepqv2+ZYxtt9vMkLNEBnHUdMYYrbVS6ui8H8F5IhGllbMhhK7rOOeZIWr3TZGzPC+IqEu2e6VU0zQ3k5/bXX10iIghhNPAYozJM6HBUdm5pIyI1loN0NS1lNKOno///w/J837fpKDLEhHbtuWc90Ls6nqdF8vVmgvRcg4A3gfrvPUjnXfOZ4bSt7XWR2Vr7ZhnGIZUo3HGVLMQDtEPQ/jHQ0glSfGPBfDeO+8PwbfoXn4XZ5/782vx4lv//Ir/4vSJmWdX/Pxavvohzr7wi3uVjk8ekY5j8ILc5Uxd3MnLmXo3lW9uRK7oa42vb8T7ufowV29v5ccl/AHj0w6V7RBvFAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d38d0cc5e305767c8bae4b3f64130be8/e4706/Image018.avif 230w", "/en/static/d38d0cc5e305767c8bae4b3f64130be8/96615/Image018.avif 412w"],
              "sizes": "(max-width: 412px) 100vw, 412px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d38d0cc5e305767c8bae4b3f64130be8/a0b58/Image018.webp 230w", "/en/static/d38d0cc5e305767c8bae4b3f64130be8/3b1af/Image018.webp 412w"],
              "sizes": "(max-width: 412px) 100vw, 412px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d38d0cc5e305767c8bae4b3f64130be8/81c8e/Image018.png 230w", "/en/static/d38d0cc5e305767c8bae4b3f64130be8/9e32a/Image018.png 412w"],
              "sizes": "(max-width: 412px) 100vw, 412px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d38d0cc5e305767c8bae4b3f64130be8/9e32a/Image018.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The InstarVision Surveillance Center allows you to stream videos to the internet. You can use this function to stream video from one installation of the Software to another and add those streams as remote cameras.`}</p>
    <p>{`This enables you to stream all your cameras from site A to site B through one open TCP port in your firewall - if you would connect each camera directly via it´s DDNS address, each camera needs a unique port forwarded to its IP address. This also helps you to manage your network resources more efficient and secure by letting your local Surveillance Center access your local cameras. While your camera limits the amount of simultaneous streams to 3, the server you use to run the Surveillance Center will not confront you with such strong restrictions. Remember that you can use the DDNS address of one of the remote camera to connect to the remote Surveillance Center. Just type in the camera´s DDNS address and add the port that you forwarded to the server IP address in the remote network. You can change the Surveillance Centers Server port in the System settings.`}</p>
    <h3 {...{
      "id": "camera-viewport",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#camera-viewport",
        "aria-label": "camera viewport permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Viewport`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "588px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cc520808da238391cd8d3e7153210360/9bbaf/Image066.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADHklEQVQoz03C608SAQAA8PvcMnOatUrITE0zTw4Q5ODgeHjA3XGnxx3PNCIeIg9RcBKipAhWZvkqdXOt5lqutZY9PzQrM8retVYr3VprfepD/0Jbn/rtB+zhVRXv5BXtLN9eWl5Qwt9SxCso4Rfu4BeW8Lb9v5hXUFy2tbC4sKh0125+eUU1f38VEBrLR6c+9cx/j17ciM9+GV38Gpn8cGTkjTPz2pp+zvY/ZZKrrYknLYlVuneFDN8gQtf1gaVm/1WN5wowtPhj4f6v2Vubk9e/LD7YvP3o27nF1+7smnfkofPk07bR9/bhV9zAM3NqzZzKM6n1f1+29L+gEmtAYjqfmXvsT99wJZe8A0tcZMEavUR5JjBnBnWcMvcuO9KP20ffOUbeWNN5bjDPDT63pNe59DqTegbQnrOO6BztnyR9M7RvinCfw46MKJiEiIgJjd0IFZFTEbklaxtYcY9/dp351H76ozP3zpl9a8+8Ag41MULdcZSLobY06TmNuwYVTLeI7BISYREeaiQCYtxbhzqbiIC15/LR4RVXLt85sxG8+KNjehOAmgwQTApgk1jFyUmv2hIU4n4J5RXjbkh/TKBvE+MOGWkX6jiJjgVRG6jzwGyf3jNuH7wHSFUIolU3IipIJpcqNU2oUY1zhNkpN3BKwqIkzAojI9XSIhUpx+h6hARRBsIcSkuE9g0BkFQIiqF6EQhJBCIpVA8JQEgghSWoFiFN+mYMkyo0UpVRbWA1OIOZrHKtWYDaEMKLMUGgtq7icENlZW3ZYZBXXVN2oLqCX1lZupfH27+vquYg2FAHietgRaNMAcMIjBkM3s6Q2+PlbHaKMQO+UI8vEnN1RF2Bbocn2NbGttu0Dk7bSms4RmNhjC0URpl0BK6maYPV2hqOJTpjya5YX7wvCcwtb8zc/nn+zu8zy38yN//krm0Mzy7353K57IlUMpAZ6p2aGDs/lp2eGEtlz4aH5sPjdyIzq/ELD+NTdwGc8xu4jmY2qGUjKBtVsXHUdgJujWqoY6BIVlNbY6JoAjeRJC1TKGvrBQcPNQgkCErYDPauv0NgJR3pwQi7AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc520808da238391cd8d3e7153210360/e4706/Image066.avif 230w", "/en/static/cc520808da238391cd8d3e7153210360/d1af7/Image066.avif 460w", "/en/static/cc520808da238391cd8d3e7153210360/8755a/Image066.avif 588w"],
              "sizes": "(max-width: 588px) 100vw, 588px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cc520808da238391cd8d3e7153210360/a0b58/Image066.webp 230w", "/en/static/cc520808da238391cd8d3e7153210360/bc10c/Image066.webp 460w", "/en/static/cc520808da238391cd8d3e7153210360/c12c1/Image066.webp 588w"],
              "sizes": "(max-width: 588px) 100vw, 588px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc520808da238391cd8d3e7153210360/81c8e/Image066.png 230w", "/en/static/cc520808da238391cd8d3e7153210360/08a84/Image066.png 460w", "/en/static/cc520808da238391cd8d3e7153210360/9bbaf/Image066.png 588w"],
              "sizes": "(max-width: 588px) 100vw, 588px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cc520808da238391cd8d3e7153210360/9bbaf/Image066.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The viewport on-screen display shows you the name of the channel and six channel status buttons.`}</p>
    <p>{`You can add up to 64 cameras to the InstarVision Surveillance Center (depending on your license type). Each camera will be displayed in its own viewport on the left of the side navigation. The amount of cameras displayed can be adjusted in the Layout menu. Each viewport offers you a couple of functions to control your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/695c7ee9d1c49cd5da5b9d0780dde666/fcda8/Image067.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "3.0434782608695654%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAADklEQVQI12NgY+cgGwEAMh8BpaZxud4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/695c7ee9d1c49cd5da5b9d0780dde666/e4706/Image067.avif 230w", "/en/static/695c7ee9d1c49cd5da5b9d0780dde666/d1af7/Image067.avif 460w", "/en/static/695c7ee9d1c49cd5da5b9d0780dde666/9984b/Image067.avif 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/695c7ee9d1c49cd5da5b9d0780dde666/a0b58/Image067.webp 230w", "/en/static/695c7ee9d1c49cd5da5b9d0780dde666/bc10c/Image067.webp 460w", "/en/static/695c7ee9d1c49cd5da5b9d0780dde666/5ca24/Image067.webp 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/695c7ee9d1c49cd5da5b9d0780dde666/81c8e/Image067.png 230w", "/en/static/695c7ee9d1c49cd5da5b9d0780dde666/08a84/Image067.png 460w", "/en/static/695c7ee9d1c49cd5da5b9d0780dde666/fcda8/Image067.png 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/695c7ee9d1c49cd5da5b9d0780dde666/fcda8/Image067.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can double-click the video with left mouse to maximize the camera´s viewport or restore it back to the multi view. A right mouse click opens the viewport layout in a full screen mode - or restores it. Your mouse wheel allows you to digitally zoom in or out of the camera view. While zoomed-in, you are able to drag the image with your left mouse button to pan or tilt your camera virtually. The "virtual Zoom Factor" will be displayed above the live video, once the Zoom is active. The viewport further offers you the following control elements in the onscreen display.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <InstarVisionCards01 mdxType="InstarVisionCards01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "overview-map",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#overview-map",
        "aria-label": "overview map permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview Map`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <InstarVisionCard01 mdxType="InstarVisionCard01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`You can rename your map by clicking on it with your left mouse button or delete it again by right-clicking it and choosing "Delete Map". Double-clicking the map opens it in place of the video canvas. Drag & drop cameras from your Camera List onto the map. The camera location pointer will change its appearance if the camera triggered a motion alert or if the software lost connection to the camera. Right-clicking the pointer gives you direct access to the camera´s live stream. You can go back to the video canvas by double-clicking any of your cameras in the Camera List.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <InstarVisionMenuCards02 mdxType="InstarVisionMenuCards02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "how-to-use-the-map-function",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-the-map-function",
        "aria-label": "how to use the map function permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to use the Map Function?`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/64c577d7605391aac53d42943d8cd77c/2bef9/InstarVision2_Map_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACXElEQVQozwXBO2/TQAAAYP8bFoT6fqV13CT2+Xnnu7Mvd36eYydNk9CmSdOltANIiAV1AMQGEqqEioSQYGNhBn4AEjAwtP+gdGAp36c8fnT64f3F+ZtX796eP33ycHa/ezwdHh8OT472Tmaj48PR/ng0nu5PZrPJZNobVqJgUYcDH2owUr59/3p98+/6783t7e3Hi9e77easIoclnkg4zJxOQTC3Au4b0KyZ9XVzdcutqZ7awoYZYOXL50+Xf37/+vnj8urqxcuziFtl5lcprDLEU0pi7HPbDS1ADZMZNrPCJMSCtCMKA6j0JRrvMIzqJTfjFBNmy4JX/SqRcVblsicRgx5zHKKjQO/IoN/NZJn1Bv0gi5TRbr47qGQlUxl1d2S3iru9ohzs5J1UloksE5HyKBNpzrMizctc91qBCNIy8QVW2sJLsraHbdfXmYDdXtZ01W27wTgshEUjCmALM6TDZsNRaWh5gYMC6GKbcqJgaia5GEz2UOhBDNIOt1HTxeDgaDoadLmgDbvhB8gmABFb5pEsi0QmpE1taClCkL1R/8Hpca+XV0V8cLifJBQRM4woZDCMQ+AatqdD6tg+aLmNemsLOK2N5ppm1ZRA4KwQNmy1hc990B92cejULVUF+tLmHKKOjpob2qJjaQC2PGz6IaSc4LbHIqKMJ8OAEc2oWT4wXcQiUjdXNH0TIHtJnSMCG8hcVBcaxjpLPBa3/cDPYiZCD+jbyvNnZ1xQ0224viEEzSp+d/nOUm3BdNQ6WAFQ10x1fuvemrawZayyyN1oLq9p801jTWus/weBSuvi9TmUpwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/64c577d7605391aac53d42943d8cd77c/e4706/InstarVision2_Map_01.avif 230w", "/en/static/64c577d7605391aac53d42943d8cd77c/d1af7/InstarVision2_Map_01.avif 460w", "/en/static/64c577d7605391aac53d42943d8cd77c/7f308/InstarVision2_Map_01.avif 920w", "/en/static/64c577d7605391aac53d42943d8cd77c/e3723/InstarVision2_Map_01.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/64c577d7605391aac53d42943d8cd77c/a0b58/InstarVision2_Map_01.webp 230w", "/en/static/64c577d7605391aac53d42943d8cd77c/bc10c/InstarVision2_Map_01.webp 460w", "/en/static/64c577d7605391aac53d42943d8cd77c/966d8/InstarVision2_Map_01.webp 920w", "/en/static/64c577d7605391aac53d42943d8cd77c/a9a89/InstarVision2_Map_01.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/64c577d7605391aac53d42943d8cd77c/81c8e/InstarVision2_Map_01.png 230w", "/en/static/64c577d7605391aac53d42943d8cd77c/08a84/InstarVision2_Map_01.png 460w", "/en/static/64c577d7605391aac53d42943d8cd77c/c0255/InstarVision2_Map_01.png 920w", "/en/static/64c577d7605391aac53d42943d8cd77c/2bef9/InstarVision2_Map_01.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/64c577d7605391aac53d42943d8cd77c/c0255/InstarVision2_Map_01.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`1`}</strong>{` - Browse the web for a map of your surveillance area. Click the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Print Screen`}</code>{` button on your keyboard to save the image to your clipboard.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ae7b767600c18d9aac4fdd470c6f56b5/2bef9/InstarVision2_Map_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACkklEQVQozyXKSU8TYQCA4fk3xsSEgxAqZSktUMqs7Xwz3+zTaacrXaCgUkyEApYWKFhNiJCAbemCxpMnf4BwEBcSF1QiKCSARqWQqAdMaojJmzyXF3n1fu/D5tbR7peDw6PPh9+//ahu7+y9fP1xd3P9xdvnn3YOqienP4+r/zs+PqlWT4+rJ79+//m6v4/ML8yvjU48WljMFYvLuUK+UMjn80v3c8Vzc8XCSqVSKZVLpXJltbJaKpfPh5XC2K1kKnMXyReWt9fXNtafbr17s7KY9Qs9MZ0Z8LAxLxvTQdgDoWQXVQHKEskAC25qsta39TQ3drYAdwR5trHxt1Y7Ozur1WpPHj/08taoRkWdZETBnSIGBJyCXZjD2t5jakZbTJQJ43EbRK2ExeXXkHuziQdLt+9kEovZycGhMM1hsuxQVSDKNO8URF1yCBTB4yi04dAKeExVOV7hVZfS2xdBPBrUNZaibRJHaBoUJcLvc/ZGIy6vW/Womi5TLGnnCIbDIG/3+ZySwikuyel1ukM+JBJ2B3t1l65BkVE0IRjUGcFBQApwhCrbRY2HIsPLHOBpWqDtkGqxNuGAxGksEPIhFG1zMCQK0G7CQtg7JI01Wa90oGZPwB3QAGDJ1s4mjEYtWFt7t1ERac2jCQpPAdIb8CC6JgQDenJ6yu1zQgEEwl5JpmkGDUYD/l6/pAqoAwes3QEpVqAZgSZpkuGB2WbmFBYhaFyWGQfAISRFe5c/pGNEl9Hc2GQyXmq42NFjbmytr2u8YDIbDG31BlO9obWhzWK83FwnuTlkZm4qNZ28Gu9PjN+Mx6+nZ9Ij48P9w/2xoWt9g6GJ5GgyMzl0YyAxEp9IjSXT4+lMai47m5oaz2Zn/wEx8CqgR4CnLwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae7b767600c18d9aac4fdd470c6f56b5/e4706/InstarVision2_Map_02.avif 230w", "/en/static/ae7b767600c18d9aac4fdd470c6f56b5/d1af7/InstarVision2_Map_02.avif 460w", "/en/static/ae7b767600c18d9aac4fdd470c6f56b5/7f308/InstarVision2_Map_02.avif 920w", "/en/static/ae7b767600c18d9aac4fdd470c6f56b5/e3723/InstarVision2_Map_02.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ae7b767600c18d9aac4fdd470c6f56b5/a0b58/InstarVision2_Map_02.webp 230w", "/en/static/ae7b767600c18d9aac4fdd470c6f56b5/bc10c/InstarVision2_Map_02.webp 460w", "/en/static/ae7b767600c18d9aac4fdd470c6f56b5/966d8/InstarVision2_Map_02.webp 920w", "/en/static/ae7b767600c18d9aac4fdd470c6f56b5/a9a89/InstarVision2_Map_02.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae7b767600c18d9aac4fdd470c6f56b5/81c8e/InstarVision2_Map_02.png 230w", "/en/static/ae7b767600c18d9aac4fdd470c6f56b5/08a84/InstarVision2_Map_02.png 460w", "/en/static/ae7b767600c18d9aac4fdd470c6f56b5/c0255/InstarVision2_Map_02.png 920w", "/en/static/ae7b767600c18d9aac4fdd470c6f56b5/2bef9/InstarVision2_Map_02.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ae7b767600c18d9aac4fdd470c6f56b5/c0255/InstarVision2_Map_02.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`2`}</strong>{` - Open MS Paint (or similar) and click `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Ctrl + V`}</code>{` at the same time to copy the image to the program. Use the cropping tools to adjust the image. After saving the image, you can add the image file as a map to the InstarVision Surveillance Center.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c9716260549aa82194d770025fd8d0f/2bef9/InstarVision2_Map_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACWUlEQVQozx3PW0vbUAAA4PyiIqhop02btklOricn93Nyb5K2SW/W2onoGA7fBxtjsAeHbxX3X/awhz0NBgO31vrQ/oKx/YKPj/r08f3Z+UlZBCfjqDdK5vPJuB+f9r0wc3SMumU3yHpuGEJbrQkHNeGAlmo60b88PNwvFtTNm8tiMnIyzAo0gTxJCbJVLzBcX5JNTtLYPXpnr7W/z+0DE0BfB5Ygm+LXb99//PxFTWb919eXb999GE3Pr4Zd3LHTsqu5ELmShhXkaVpg6AGCngpd1SaGakM/cB8ffz+vn6msjPIinV6MEVFFIsNQn87meZkjrEEMeZ0VYL0lHWEMPKwPy8SPSb8oVk+r7XZDDUYlC9gG26gcVFiBTbI07adRgi0XIQfh0AkjywucyXiQdAKbWGEaJVm8Wi03mw11+eoiin2daKqt8irnugp2FC8hfoJxQGzPhhZUTMUO3YZI17gjUVOABJbLP9vNlhpOcuxrdf6Q5o6QZxeplRJBJ0YT1NpSwyRmna82hCpU6lke4JA4nqHp8Gn19E8OOjjouLolyxrrhvbVzXWaxXHsNGW2KbSRhTjIQkOJIj8IfT8KSeTrlrFer7fbLXX28mw6G8ahgT0ka3ynG6mO6kSu4Ri8xFqOBk0FWUpLZji1zbRpUWUZwCyX/8/zSd4f9wSZo7kqI4Mqfbh7vAMNheGPd+uVKl0BIsOAGt1+AdR2CzSBzCoILBb3i8WCCqLOdFaSyBD1lmJbimO0BNoiqBj3eEu0DNVEwEuc3iBPe2FeZMOTQd6L7+7uPt/e/gXwOQADyPGj7QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c9716260549aa82194d770025fd8d0f/e4706/InstarVision2_Map_03.avif 230w", "/en/static/4c9716260549aa82194d770025fd8d0f/d1af7/InstarVision2_Map_03.avif 460w", "/en/static/4c9716260549aa82194d770025fd8d0f/7f308/InstarVision2_Map_03.avif 920w", "/en/static/4c9716260549aa82194d770025fd8d0f/e3723/InstarVision2_Map_03.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c9716260549aa82194d770025fd8d0f/a0b58/InstarVision2_Map_03.webp 230w", "/en/static/4c9716260549aa82194d770025fd8d0f/bc10c/InstarVision2_Map_03.webp 460w", "/en/static/4c9716260549aa82194d770025fd8d0f/966d8/InstarVision2_Map_03.webp 920w", "/en/static/4c9716260549aa82194d770025fd8d0f/a9a89/InstarVision2_Map_03.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c9716260549aa82194d770025fd8d0f/81c8e/InstarVision2_Map_03.png 230w", "/en/static/4c9716260549aa82194d770025fd8d0f/08a84/InstarVision2_Map_03.png 460w", "/en/static/4c9716260549aa82194d770025fd8d0f/c0255/InstarVision2_Map_03.png 920w", "/en/static/4c9716260549aa82194d770025fd8d0f/2bef9/InstarVision2_Map_03.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c9716260549aa82194d770025fd8d0f/c0255/InstarVision2_Map_03.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`3`}</strong>{` - Drag and drop your cameras from the camera list onto the map. Position them according to their installation location.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5b9fe35976841255b8b82877e43fee12/2bef9/InstarVision2_Map_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACTklEQVQozy2PyW7aQABA/UdVmkRqCQlLwDZ4m7HH+8zYHsDGuKaYTTSJKiWKVKlqj1WP4RqSr8k3kBSOOcC5itrbuzw9Pe5mmkx71iL1LzLyZYDfIPVnGYli18QozdMoGXgRM1xYlU+qcqmmVnXfeFitHlYr7iqnI6qMIziOYBGA3JeJLbhUd4miIl4B/HHl/eH58VHzkDd4xddEq9XShaenp+fnNZdmISaQYi30lIQh7KtO6AEPSEhsmy3ZlhVPgxgAXwWuZnlIMVXXM9fr9evrK2djiByFhrYXOFHc1W0Q9VgxGy/mxcViPJnledaV1Jrl8L4DPqURCf1ekmy22/1+zw1HCcNaniXFbDqazAKGozjMh9mvy8nvSfR9FN8WWSciwzxlHWr7VtANKKObzZ/dbsddfl0Q6iJPl5HCq02MIcU66YQX85tvxXBowcuOa7sQEafWrpb5kzZU+Zbw8vLyv+wSeMZ/qAhl6JlxhCKnadEgnf68vv4xoPaCtVT9XJLK3S52qWtjpAJlu9nu9nuOMg8zGyJJBg2HmFe3150u7cWBGcYuIf1+vxNYyIaEepRiElA/IMDQt/+ep/PpaJKF1PB8KGk8S0LgaBY1oak1WnXDVBVDgabSkGuC1qw1ziSVrwjVzWbzJi+Kfn/Yb8tCRSjVFbFUKR2WDwBSasLZUe2gVHnXkup18fT0/KOoNupiXZSbksbf398/Pj5ymEafi75NgACqbWRIJqwKJ4ajxVmX10UdSlDjHWr2Boz1CIvZYDiIOni5XC7v7v4CkwwHEW4R58sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b9fe35976841255b8b82877e43fee12/e4706/InstarVision2_Map_04.avif 230w", "/en/static/5b9fe35976841255b8b82877e43fee12/d1af7/InstarVision2_Map_04.avif 460w", "/en/static/5b9fe35976841255b8b82877e43fee12/7f308/InstarVision2_Map_04.avif 920w", "/en/static/5b9fe35976841255b8b82877e43fee12/e3723/InstarVision2_Map_04.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5b9fe35976841255b8b82877e43fee12/a0b58/InstarVision2_Map_04.webp 230w", "/en/static/5b9fe35976841255b8b82877e43fee12/bc10c/InstarVision2_Map_04.webp 460w", "/en/static/5b9fe35976841255b8b82877e43fee12/966d8/InstarVision2_Map_04.webp 920w", "/en/static/5b9fe35976841255b8b82877e43fee12/a9a89/InstarVision2_Map_04.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b9fe35976841255b8b82877e43fee12/81c8e/InstarVision2_Map_04.png 230w", "/en/static/5b9fe35976841255b8b82877e43fee12/08a84/InstarVision2_Map_04.png 460w", "/en/static/5b9fe35976841255b8b82877e43fee12/c0255/InstarVision2_Map_04.png 920w", "/en/static/5b9fe35976841255b8b82877e43fee12/2bef9/InstarVision2_Map_04.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5b9fe35976841255b8b82877e43fee12/c0255/InstarVision2_Map_04.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`4`}</strong>{` - Double-click the white camera icon to open a window with the camera´s live video feed. Close the feed by pressing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Esc`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3254bca1a3fbf613ad1d48f1670916f8/2bef9/InstarVision2_Map_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACZUlEQVQozxXO22rTYAAA4LyRpw5cj0nTnJP/z98kTf7kz7lr0y2u7bRUdO6AIgw3oTBPNxPZnaIXmxeCsEfYlQ8wXS3MJ1DBTvYAH3zU3rMnG+Pey+3V50/vvV3yD9IoH3RGPRJ3sEXM3p0s7i67cWJgRKulmrrIABph9OnD+y+fj6lXLyZWgDHWgMUHQu2BZejE9AjCvqoZnKLzC9WbBbawwN3im5zmQclWOI3dmezvH7yjNrbvP9x8FA86mqZ2dEkPEYlc3YG6rUCsIRdC0kQ+0j0AMWy5hmZBzzXenJ4d/vhNrY6Wd/d2dieT8frmeJi7sbMyHLTzrIl13YWSIciIaahl2xFcG+a9iERkOeu+Pj07PP9DDUZ50klwalaEIotYGJnD/jBzkOkgvaXbpOUHlkes/upKmoYtrxW2wzghH799//rrL9Uf3kE64KR6hS2zAhstRWsWmNAVp01cHzvEhhbUTGAHmJFrZb4oI8AJ3Gx6fnl5SeX9jhs0GalUl2qmb6epNTTYLb5Oy1VeZUzPrIlFWloEajVNPRxgm5gqUH5OZ//mc8pPMElsZMgyZB3fWn+8laSBF5h1latLDWTpPGwAQw1D1w8ICQMv8qGBZrPZfD6n7o7WBmsrYWBgDymQT7NYx7od2oZjiApv2QgYGmoBTmUEyDFcVYFCla9Op9Or9njYzfqZrAq0WGKBVKZLhcoNaAKGLxfo68XaNUmp18VKhV0UQYMVGVHhZMBfXFxcYez5eb9jeBqnVcQmkgxYbdxutrR2L2b1BgQiVBsWaSbdKEq9qB1meTeI8NHR8cnJyX/SuAANegS/UQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3254bca1a3fbf613ad1d48f1670916f8/e4706/InstarVision2_Map_05.avif 230w", "/en/static/3254bca1a3fbf613ad1d48f1670916f8/d1af7/InstarVision2_Map_05.avif 460w", "/en/static/3254bca1a3fbf613ad1d48f1670916f8/7f308/InstarVision2_Map_05.avif 920w", "/en/static/3254bca1a3fbf613ad1d48f1670916f8/e3723/InstarVision2_Map_05.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3254bca1a3fbf613ad1d48f1670916f8/a0b58/InstarVision2_Map_05.webp 230w", "/en/static/3254bca1a3fbf613ad1d48f1670916f8/bc10c/InstarVision2_Map_05.webp 460w", "/en/static/3254bca1a3fbf613ad1d48f1670916f8/966d8/InstarVision2_Map_05.webp 920w", "/en/static/3254bca1a3fbf613ad1d48f1670916f8/a9a89/InstarVision2_Map_05.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3254bca1a3fbf613ad1d48f1670916f8/81c8e/InstarVision2_Map_05.png 230w", "/en/static/3254bca1a3fbf613ad1d48f1670916f8/08a84/InstarVision2_Map_05.png 460w", "/en/static/3254bca1a3fbf613ad1d48f1670916f8/c0255/InstarVision2_Map_05.png 920w", "/en/static/3254bca1a3fbf613ad1d48f1670916f8/2bef9/InstarVision2_Map_05.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3254bca1a3fbf613ad1d48f1670916f8/c0255/InstarVision2_Map_05.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`5`}</strong>{` - The camera icon will start to flash red, whenever an alarm is triggered. Double-click the camera name inside the Camera List to get back the Video Canvas.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ebae8ebec200d798eb9f51e040d2b6ae/2bef9/InstarVision2_Map_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACb0lEQVQozxXP207TYAAA4D6PFwYFYthGu67H9fT37/n0t2vp7LaObTA28IQgmBD1gsTEQGJC0HCjMSYkemEivoFe+QCgbAk+gAkxw/AA38WHHbze23660cjj+6v5oF9vdRcebzxaW27UUttGVqvTTBq5n6SGp5NyiZSLFUAagf7x/bsvnz9hz3a2loZ923dCl6cFQmCKi90min3T5WSDFQE7S9yepmZmmGlGYyRPqVoCC+id3ZevDt5i/dV2p9d6sbvr1hHQrXUoNtvIQJZi8tCVoQeAr0EE1UBRXWB5hmQqKDD3v58enf7F8t7dRis9fHO4vfP83vrmYLBUS1w/9qGjqp5a1TkBkpRYsh3Os0G7WQtqft5s7P04O/p1iS0PFy2kr6wOlJDFhXlWKNmhaLm6bgHVgnZgocjwA7PbzZM0tHwrSqMkjT78PPt6cYn1+h2gyqqmkDQxSxQMltiMQi8OvMj2Qs8OHGAC2VTs0CVFosQWBaiwVW58/vvq6grr9DLLVXB2DmeKmm9lsRGaFHQgVcUZiTR8g+AL5eqcIuMLaeCGrh3oiiqPRuN/kwkW1wOUONCQJEg7ofnwyXqa1qLIrkgMWaWhqbIqoxhSFPooREEU+hGCpjYejyeTCTZcG/b6eQ3png9FwMRZrJtS5ELdNTiRMW0VGLJmKbREcoAhaVxUmDJfPh+NrvGDlTzrZILMEVyBkvgZsgSmb+xP3Sry+CwxVcBvVoUKyc/PU3O8QlN8hRcZSeUuLv5cn6M47fQyG0FeLQuGztmaRt7ZospJu85qvKaKELBOaNabSVJHSZa0Os04RcfHx99OTv4DVDoBVT/y7SwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ebae8ebec200d798eb9f51e040d2b6ae/e4706/InstarVision2_Map_06.avif 230w", "/en/static/ebae8ebec200d798eb9f51e040d2b6ae/d1af7/InstarVision2_Map_06.avif 460w", "/en/static/ebae8ebec200d798eb9f51e040d2b6ae/7f308/InstarVision2_Map_06.avif 920w", "/en/static/ebae8ebec200d798eb9f51e040d2b6ae/e3723/InstarVision2_Map_06.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ebae8ebec200d798eb9f51e040d2b6ae/a0b58/InstarVision2_Map_06.webp 230w", "/en/static/ebae8ebec200d798eb9f51e040d2b6ae/bc10c/InstarVision2_Map_06.webp 460w", "/en/static/ebae8ebec200d798eb9f51e040d2b6ae/966d8/InstarVision2_Map_06.webp 920w", "/en/static/ebae8ebec200d798eb9f51e040d2b6ae/a9a89/InstarVision2_Map_06.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ebae8ebec200d798eb9f51e040d2b6ae/81c8e/InstarVision2_Map_06.png 230w", "/en/static/ebae8ebec200d798eb9f51e040d2b6ae/08a84/InstarVision2_Map_06.png 460w", "/en/static/ebae8ebec200d798eb9f51e040d2b6ae/c0255/InstarVision2_Map_06.png 920w", "/en/static/ebae8ebec200d798eb9f51e040d2b6ae/2bef9/InstarVision2_Map_06.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ebae8ebec200d798eb9f51e040d2b6ae/c0255/InstarVision2_Map_06.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`6`}</strong>{` - If the camera icon turns into a red, blinking exclamation point, the software has lost the connection to the camera.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      